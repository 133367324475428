<template>
    <!--    编辑-->
    <el-dialog :title="record.expertName" :visible.sync="visiable" width="45vw" class="addAlameBox"
        :close-on-click-modal="false" @close="handleCancel" :destroy-on-close="true">
        <div style="max-height: 65vh">
            <div class="list_box" ref="box">
                <span v-for="item in contentLists" :key="item.id">
                    <div class="left_i" v-if="item.creator !== userName">
                        <div v-html="item.content"></div>
                    </div>
                    <div class="right_out" v-if="item.creator === userName">
                        <div class="right_i">
                            <div v-html="item.content"></div>
                        </div>
                    </div>
                </span>

            </div>
            <div class="send_mess_box">
                <div>
                    <!-- <el-input type="textarea" v-model="message" placeholder="请在此输入咨询问题"></el-input> -->
                    <div style="width: 100%" id="editor">
                        <Editor v-model="message" />
                    </div>
                </div>
                <div class="btn_line">
                    <el-button type="primary" size="small" @click="sendMessage">发送</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import Editor from "@/components/lxw/Editor/index";
export default {
    components: { Editor },
    props: {
        visiable: {
            type: Boolean,
            default: false,
        },
        record: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },

    data() {
        return {
            userName: "",
            contentLists: [],
            message: '',
            idflag: null
        };
    },
    watch: {
        visiable: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getList()
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.idflag = window.localStorage.getItem('label')
    },
    methods: {
        getList() {
            // 用户信息
            if (this.$store.state.user) {
                this.userName = this.$store.state.user;
            }
            if (this.record.expert) {
                this.$get("/leaveMsg/page", {
                    page: this.page,
                    size: this.size,
                    expert: this.record.expert,
                    referee: this.userName
                }).then(async (res) => {
                    if (res.data.state == "success") {
                        this.contentLists = res.data.data.reverse();
                        const box = this.$refs.box
                        this.$nextTick(() => {
                            box.scrollTop = box.scrollHeight
                        })
                    }
                });
            }

        },
        handleCancel() {
            this.contentLists = []
            this.$emit("close");
        },

        sendMessage() {
            if (this.idflag == 0) {
                // 用户回复
                this.$postJSON('/leaveMsg/referee', {
                    content: this.message,
                    msgType: 0, // 0-用户咨询、1-专家回复
                    expert: this.record.expert,
                    referee: this.userName
                    // fjs: []
                }
                ).then((res) => {
                    if (res.data.state == 'success') {
                        // this.$message.success(res.data.msg);
                        this.message = ''
                        this.getList()
                        // this.$emit("success");
                    } else {
                        this.$message.error(res.data.msg);
                    }

                })
            } else if (this.idflag == 1) {
                //console.log("专家身份不能在专家页面进行回复")
                this.$message.error("专家身份不能在专家页面进行回复");
            }

        }
    }
};
</script>

<style scoped>
.list_box {
    width: 100%;
    height: 45vh;
    overflow-y: auto;
    color: #FFFFFF;
}

.left_i {
    width: 65%;
    background-color: #dcd9d9;
    border-radius: 20px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
    
    color: #000000;
}
.left_i ::v-deep img {
    max-height: 20vh !important;    
    max-width: 100% !important;
}
.right_out {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.right_i {
    width: 65%;
    background-color: #66B1FF;
    border-radius: 20px;
    padding: 15px;
    box-sizing: border-box;
}

.right_i ::v-deep img {
    max-height: 20vh !important;    
    max-width: 100% !important;
}

.send_mess_box {
    width: 100%;
    height: 20vh;
}

.btn_line {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    margin-top: 20px;
}

::v-deep .el-textarea__inner {
    height: 15vh !important;
}

#editor ::v-deep .quill-editor .ql-container {
    height: 110px !important;
}

#editor .ql-editor {
    height: 110px;
}
</style>